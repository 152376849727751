import React, { useState, useEffect } from 'react';
import videojs from "video.js";
import 'video.js/dist/video-js.css';
import { data } from '../data.js';

let player = null;

export default function MainVideo({ togglePlay, isVisible, onMVTimeUpdate, onVideoEnded }) {
  let [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    player = videojs(document.getElementById("main-video"), {
      controls: false,
      muted: false,
      width: window.VIDEO_WIDTH,
      height: window.VIDEO_HEIGHT,
      sources: [{
        type: "video/webm",
        src: data.mainVideoSrc
      }]
    }, () => {
      player.on('timeupdate', onTimeUpdate);
      player.on('ended', onVideoEnded)
    });
  }, []);

  useEffect(() => {
    if (togglePlay) player.play();
  }, [togglePlay]);

  function onTimeUpdate() {
    if (onMVTimeUpdate(player.currentTime())) {
      player.pause();
    };
  }

  function play() {
    player.play();
    setIsPlaying(true);
  }

  return (
    <div className="main-video" style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
      <video id="main-video" className="video-js vjs-default-skin"></video>
      {
        !isPlaying ?
          <i className="material-icons play-icon" onClick={play}>power_settings_new</i>
          : null
      }
    </div>
  )
}
