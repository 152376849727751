import mainVideoSrc from './videos/main.webm';

import midroll from './videos/mid1.webm';
import midroll2 from './videos/mid2.webm';
import midroll3 from './videos/mid3.webm';
import midroll4 from './videos/mid4.webm';


export const data = {
  mainVideoSrc,

  ratio: 4 / 3,

  btnText: 'Next channel',

  midRolls: [
    {
      id: 1,
      name: 'mid1',
      src: midroll,
      canSkipAfter: 0,
      showAt: 60
    }, {
      id: 2,
      name: 'mid2',
      src: midroll2,
      canSkipAfter: 0,
      showAt: 60
    },
    {
      id: 3,
      name: 'mid3',
      src: midroll3,
      canSkipAfter: 0,
      showAt: 60
    },
    {
      id: 4,
      name: 'mid4',
      src: midroll4,
      canSkipAfter: 0,
      showAt: 60
    }
  ]
}
