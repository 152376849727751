import React, { useState, useEffect } from 'react';
import 'video.js/dist/video-js.css';
import './app.css';
import MainVideo from './components/MainVideo';
import MidRoll from './components/MidRoll';
import { data } from './data.js';
import axios from 'axios';

let mainPlayerTime = 0;
let lastMidroll = 0;
let results = {};
let userid = 0;
let cell = 0;
let par_20 = 0;
let params = '';
window.THRESHOLD = 0.3;

function App() {
  let [isMidroll, setIsMidroll] = useState(false);
  let [currMidroll, setCurrentMidroll] = useState(0);
  let [togglePlay, setTogglePlay] = useState(false);

  useEffect(() => {
    params = new URLSearchParams(window.location.search);
    userid = params.get('userid');
    cell = params.get('cell');
    par_20 = params.get('par_20');
    params = params.toString();
  }, []);


  let onSetMidRoll = isMidroll => {
    setIsMidroll(isMidroll);
  }

  function showMidroll() {
    for (let i = lastMidroll; i < data.midRolls.length; i++) {
      if (mainPlayerTime >= data.midRolls[i].showAt && (mainPlayerTime - window.THRESHOLD >= data.midRolls[i].showAt)) {
        setCurrentMidroll(i);
        setIsMidroll(true);
        setTogglePlay(false);
        return true;
      }
    }
    return false;
  }

  function onMVTimeUpdate(currentTime) {
    mainPlayerTime = currentTime;
    return showMidroll();
  }

  function onMidrollEnded(id, totalTime) {
    setIsMidroll(false);
    results[id] = totalTime.toFixed(2);
    lastMidroll = currMidroll + 1;

    if (!showMidroll()) {
      setTogglePlay(true);
    }
  }

  function onVideoEnded() {
    axios.post("http://api.eyeseetest.com/tests/premidroll/save", {
      userid,
      cell,
      data: JSON.stringify(results),
    }).then(response => {
      window.videoId = "finish";
      window.innerVideoTime = "1";
      window.location.href = `https://www.surveygizmo.com/s3/5554125/2020062-redirects?${params}`
    })
  }

  return (
    <div className="app">
      <div className="videos">
        <MainVideo
          isVisible={!isMidroll}
          onSetMidRoll={onSetMidRoll}
          onMVTimeUpdate={onMVTimeUpdate}
          togglePlay={togglePlay}
          onVideoEnded={onVideoEnded}
        />

        {
          data.midRolls.map((midroll, i) => (
            <MidRoll
              midroll={midroll}
              key={midroll.id}
              isVisible={isMidroll && currMidroll === i}
              onMidrollEnded={onMidrollEnded}
              btnText={data.btnText} />
          ))
        }
      </div>
    </div>
  );
}

export default App;
