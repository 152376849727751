import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {data} from './data';

window.VIDEO_WIDTH = window.innerWidth;
window.VIDEO_HEIGHT = window.innerHeight;

(function calcDimensions() {
  let ratio = data.ratio;
  let width;
  let height;
  if (window.innerWidth > window.innerHeight && (window.innerWidth / ratio > window.innerHeight)) {
    window.VIDEO_WIDTH = window.innerHeight * ratio;
    window.VIDEO_HEIGHT = window.innerHeight;
    return;
  }
  window.VIDEO_WIDTH = window.innerWidth;
  window.VIDEO_HEIGHT = window.innerWidth / ratio;
}())

ReactDOM.render(<React.StrictMode>
  <App/>
</React.StrictMode>, document.getElementById('root'));
