import React, { useState, useEffect } from 'react';
import videojs from 'video.js';

export default function MidRoll({ midroll, isVisible, onMidrollEnded, btnText }) {
  let [canSkip, setCanSkip] = useState(false);
  let [player, setPlayer] = useState(null);
  let startTime = 0;
  let endTime = 0;

  useEffect(() => {
    let p = videojs(document.getElementById('mr-' + midroll.id), {
      muted: false,
      controls: false,
      width: window.VIDEO_WIDTH,
      height: window.VIDEO_HEIGHT,
      sources: [
        {
          type: "video/webm",
          src: midroll.src
        }
      ]
    });
    setPlayer(p);
    startTime = 0;
    endTime = 0;
  }, []);


  useEffect(() => {
    if (isVisible) {
      onShow();
    }
  }, [isVisible]);


  function onShow() {
    player.play().then(() => {
      //  startTime = Date.now();
      //   startTime = player.currentTime();
      player.on('timeupdate', checkCanSkip);
      player.on('pause', onEnd);
      player.on('ended', onEnd);
    });
  }

  function checkCanSkip() {
    if (!player.paused() && player.currentTime() - window.THRESHOLD >= midroll.canSkipAfter) setCanSkip(true);
  }

  function onEnd() {
    if (endTime) return;
    //  endTime = Date.now();
    endTime = player.currentTime();
    setCanSkip(false);
    // let start = moment(startTime);
    // let end = moment(endTime);
    // let totalTime = end.diff(start) / 1000;
    onMidrollEnded(midroll.id, endTime);
  }

  function onSkip() {
    player.pause();
  }

  return (<div className="midroll-video-wrapper" style={{
    visibility: isVisible
      ? 'visible'
      : 'hidden'
  }}>
    <div className="midroll-video">
      <video className="midroll-video video-js vjs-default-skin" id={"mr-" + midroll.id}></video>

      {
        canSkip
          ? (
            <button className="skip-ad-btn" onClick={onSkip}>
              <span>{btnText}</span>
              <i className="material-icons">skip_next</i>
            </button>
          )
          : null
      }
    </div>

  </div>)
}
